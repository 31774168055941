<template>
  <v-card
    class="mt-2"
    :loading="loading"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-text>
      <vue-apex-charts
        :key="componentKey"
        type="bar"
        height="350"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </v-card-text>
    <v-card-actions>
      <v-row >
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <date-picker-custom
            :label="'Date minimum'"
            :date="dateMin"
            @update:date="minDateChange"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <date-picker-custom
            :label="'Date maximum'"
            :date="dateMax"
            @update:date="maxDateChange"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {
  getCurrentInstance,
  onMounted,
  ref,
  watch,
  nextTick,
} from '@vue/composition-api/dist/vue-composition-api'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import store from '@/store'

export default {
  name: 'BarChart',

  emits: ['mount'],

  components: {
    DatePickerCustom,
    VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    dat: {
      type: Array,
      default: null,
    },
    cat: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dateMin: {
      type: String,
      default: null,
    },
    dateMax: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const minDate = ref(null)
    const maxDate = ref(null)

    const barChart = {
      series: [
        {
          data: props.dat,
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#3c4a11',
        plotOptions: {
          bar: {
            horizontal: false,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: props.cat,
        },
        yaxis: {
          // opposite: isRtl,
        },
      },
    }

    const componentKey = ref(0)

    const forceRerender = () => {
      componentKey.value += 1
    }

    const instance = getCurrentInstance()

    const fitlerStat = (date, isMin) => {
      const lang = false
      instance.emit('mount', { date, isMin, lang })

      barChart.series[0].data = []
      barChart.series[0].data.push(...props.dat)
      barChart.chartOptions.xaxis.categories = []
      barChart.chartOptions.xaxis.categories.push(...props.cat)
      //forceRerender()
    }

    watch(() => store.state.user.lang, () => {
      instance.emit('mount', { date: null, isMin: false, lang: true })
      barChart.series[0].data = []
      barChart.series[0].data.push(...props.dat)
      barChart.chartOptions.xaxis.categories = []
      barChart.chartOptions.xaxis.categories.push(...props.cat)
      //forceRerender()
    }, { deep: true })

    const minDateChange = event => {
      minDate.value = event
      fitlerStat(event, true)
    }

    const maxDateChange = event => {
      maxDate.value = event
      fitlerStat(event, false)
    }

    return {
      minDate,
      maxDate,
      minDateChange,
      maxDateChange,
      componentKey,
      fitlerStat,
      picker,
      menuref,
      menu,
      barChart,
    }
  },
}
</script>

<style scoped>

</style>
