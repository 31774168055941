<template>
  <v-card
    class="mt-2"
    :loading="loading"
  >
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="align-start font-weight-semibold text-sm mb-1 display-1">
          {{ statTitle }}
        </v-card-title>
        <v-card-subtitle><span class="font-weight-semibold text-2xl me-1 mb-2">{{ statistics }}</span></v-card-subtitle>
      </div>

      <v-avatar
        :color="color"
        size="48"
        class="elevation-3 v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          :color="color"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text class="text-no-wrap text--primary mt-1">
      <v-chip
        x-large
        :color="color"
        label
        class="mx-auto mb-3"
        outlined
      >
        <span class="font-weight-bold display-1">{{ formatAmount(amount) }}</span>
      </v-chip>

      <p class="text-xs text--secondary font-weight-bold mb-0">
        Frais <v-chip

          label
          class="font-weight-bold"
          :color="color"
        >
          <span>{{ formatAmount(subtitle) }}</span>
        </v-chip>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { intToCurrency } from '@core/dynamicComponents/dynamicComponents'
import { watch, getCurrentInstance } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'

export default {
  name: 'AmountStat',

  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: Number, default: null },
    statistics: { type: Number, default: null },
    change: { type: String, default: '' },
    amount: { type: Number, default: null },
    loading: { type: Boolean, default: false },
  },
  setup() {
    const instance = getCurrentInstance()

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    watch(() => store.state.user.lang, () => {
      instance.emit('mount')
    }, { deep: true })

    return {
      mdiDotsVertical,
      checkChange,
      formatAmount,
    }
  },
}
</script>

<style scoped>

</style>
