<template>
  <v-card
    class="mt-2"
    :loading="loading"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <!-- chart -->
    <v-card-text>
      <chartjs-component-doughnut-chart
        :height="275"
        :data="doughnutChart.data"
        :options="doughnutChart.options"
        class="mb-3"
      />
    </v-card-text>
    <!--/ chart -->
    <!-- stocks -->
    <v-card-text>
      <div
        v-for="(stock,index) in stockData"
        :key="stock.device"
        :class="index < stockData.length-1 ? 'mb-4':''"
        class="d-flex justify-space-between"
      >
        <div class="d-flex align-center">
          <v-icon
            size="16"
            :color="stock.color"
          >
            {{ stock.symbol }}
          </v-icon>
          <span class="font-weight-bold ms-2 me-2">{{ stock.device }}</span>
          <v-chip
            :color="stock.color"
            small
            label
          >
            <span class="font-weight-bold">{{ formatAmount(stock.percentage) }}</span>
          </v-chip>
        </div>
        <div>
          <span>{{ stock.upDown }}%</span>
          <v-icon
            size="14"
            :color="stock.upDown > 25 ? 'success':'error'"
          >
            {{ stock.upDown > 25 ? mdiArrowUp:mdiArrowDown }}
          </v-icon>
        </div>
      </div>
    </v-card-text>
    <!--/ stocks -->
    <v-card-actions>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <date-picker-custom
            :label="'Date minimum'"
            :date="dateMin"
            @update:date="minDateChange"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <date-picker-custom
            :label="'Date maximum'"
            :date="dateMax"
            @update:date="maxDateChange"
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiMonitor, mdiTabletAndroid, mdiArrowUp, mdiArrowDown, mdiCash,
} from '@mdi/js'
import ChartjsComponentDoughnutChart from '@core/components/charts/ChartjsComponentDoughnutChart'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import { getCurrentInstance, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import { intToCurrency } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'DoughnutChart',
  components: {
    DatePickerCustom,
    ChartjsComponentDoughnutChart,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dateMin: {
      type: String,
      default: null,
    },
    dateMax: {
      type: String,
      default: null,
    },
    dat: {
      type: Array,
      default: null,
    },
    cat: {
      type: Array,
      default: null,
    },
    color: {
      type: Array,
      default: null,
    },
    percent: {
      type: Array,
      default: null,
    },
  },

  setup(props) {
    const instance = getCurrentInstance()

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const doughnutChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${formatAmount(value)}`

              return output
            },
          },

          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
        },
      },
      data: {
        datasets: [
          {
            labels: props.cat,
            data: props.dat,
            backgroundColor: props.color,
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      },
    }

    const stockData = ref([])

    const initStockData = () => {
      stockData.value = []
      for (let i = 0; i < props.dat.length; i++) {
        stockData.value.push({
          device: props.cat[i],
          symbol: mdiCash,
          color: props.color[i],
          percentage: props.dat[i],
          upDown: props.percent[i],
        })
      }
    }

    initStockData()

    const fitlerStat = (date, isMin) => {
      const lang = false
      instance.emit('mount', { date, isMin, lang })

      doughnutChart.data.datasets[0].labels = []
      doughnutChart.data.datasets[0].labels.push(...props.cat)
      doughnutChart.data.datasets[0].data = []
      doughnutChart.data.datasets[0].data.push(...props.dat)
      doughnutChart.data.datasets[0].backgroundColor = []
      doughnutChart.data.datasets[0].backgroundColor.push(...props.color)
      initStockData()
    }

    const minDateChange = event => {
      fitlerStat(event, true)
    }

    const maxDateChange = event => {
      fitlerStat(event, false)
    }

    watch(() => store.state.user.lang, () => {
      instance.emit('mount', { date: null, isMin: false, lang: true })
      doughnutChart.data.datasets[0].labels = []
      doughnutChart.data.datasets[0].labels.push(...props.cat)
      doughnutChart.data.datasets[0].data = []
      doughnutChart.data.datasets[0].data.push(...props.dat)
      doughnutChart.data.datasets[0].backgroundColor = []
      doughnutChart.data.datasets[0].backgroundColor.push(...props.color)
      initStockData()
    }, { deep: true })

    return {
      doughnutChart,
      formatAmount,
      mdiArrowUp,
      mdiArrowDown,
      stockData,
      minDateChange,
      maxDateChange,

    }
  },
}
</script>

<style scoped>

</style>
