<template>
  <div>
    <user-stats />
    <v-row>

      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <amount-stat
          :key="componentPayStatKey"
          :change="$store.state.payment.globalStatValue[0].percent"
          :loading="loadingGlobalStatPay"
          :color="$store.state.payment.globalStatValue[0].color"
          :icon="icons.mdiCashPlus"
          :statistics="$store.state.payment.globalStatValue[0].quantity"
          :stat-title="$store.state.payment.globalStatKeys[0]"
          :subtitle="$store.state.payment.globalStatValue[0].fee"
          :amount="$store.state.payment.globalStatValue[0].value"
          @mount="getGlobalPayStat"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <amount-stat
          :key="componentPayStatKey"
          :change="$store.state.payment.globalStatValue[1].percent"
          :loading="loadingGlobalStatPay"
          :color="$store.state.payment.globalStatValue[1].color"
          :icon="icons.mdiCashMinus"
          :statistics="$store.state.payment.globalStatValue[1].quantity"
          :stat-title="$store.state.payment.globalStatKeys[1]"
          :subtitle="$store.state.payment.globalStatValue[1].fee"
          :amount="$store.state.payment.globalStatValue[1].value"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentReasonKey"
          title="Paiments en fonction du motif"
          subtitle="Montant totaux des paiements en fonction du motif"
          :loading="loadingReason"
          :date-min="$store.state.payment.filterReason.min_date"
          :date-max="$store.state.payment.filterReason.max_date"
          :dat="$store.state.payment.dataReasonValueY"
          :cat="$store.state.payment.catReasonValueX"
          :color="$store.state.payment.dataReasonValueColor"
          :percent="$store.state.payment.dataReasonValuePercent"
          @mount="filterReason"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentValidityKey"
          title="Réservations validées"
          subtitle="Montant totaux des réservations validées"
          :loading="loadingValidity"
          :date-min="$store.state.payment.filterValidity.min_date"
          :date-max="$store.state.payment.filterValidity.max_date"
          :dat="$store.state.payment.dataValidityValueY"
          :cat="$store.state.payment.catValidityValueX"
          :color="$store.state.payment.dataValidityValueColor"
          :percent="$store.state.payment.dataValidityValuePercent"
          @mount="filterValidity"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentDepositValueKey"
          title="Réservations en valeur"
          subtitle="Statistiques en valeur sur les réservations"
          :loading="loadingDeposit"
          :date-min="$store.state.payment.filterDepositValueStat.min_date"
          :date-max="$store.state.payment.filterDepositValueStat.max_date"
          :dat="$store.state.payment.dataDepositValueY"
          :cat="$store.state.payment.catDepositValueX"
          :color="$store.state.payment.dataDepositValueColor"
          :percent="$store.state.payment.dataDepositValuePercent"
          @mount="filterBooking"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <DoughnutChart
          :key="componentWithdrawValueKey"
          title="Retraits des fonds"
          subtitle="Retraits des fonds par les membres"
          :loading="loadingWithdrawValue"
          :date-min="$store.state.payment.filterWithdrawValueStat.min_date"
          :date-max="$store.state.payment.filterWithdrawValueStat.max_date"
          :dat="$store.state.payment.dataWithdrawValueY"
          :cat="$store.state.payment.catWithdrawValueX"
          :color="$store.state.payment.dataWithdrawValueColor"
          :percent="$store.state.payment.dataWithdrawValuePercent"
          @mount="filterWithdrawValue"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <bar-chart
          :key="componentKey"
          :title="'Réservations'"
          :subtitle="'Statistiques sur les réservations'"
          :loading="loading"
          :dat="$store.state.payment.dataY"
          :cat="$store.state.payment.catX"
          :date-min="$store.state.payment.filterStat.min_date"
          :date-max="$store.state.payment.filterStat.max_date"
          @mount="filterPay"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <bar-chart
          :key="componentWithdrawKey"
          :title="'Retraits'"
          :subtitle="'Statistiques sur les retraits des fonds'"
          :loading="loadingWithdraw"
          :dat="$store.state.payment.dataWithdrawY"
          :cat="$store.state.payment.catWithdrawX"
          :date-min="$store.state.payment.filterWithdraw.min_date"
          :date-max="$store.state.payment.filterWithdraw.max_date"
          @mount="filterWithdraw"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-actions>
            {{ $t("Period :")}}
            <v-spacer />
            <span class="mr-4">{{ $t('De')}}</span>
            <date-picker-custom  class="mr-4"
                                 :label="'Date minimum'"
                                 :date="minDate"
                                 @update:date="minDateChange"
            />
            <span class="ml-6 mr-4">{{ $t('à') }}</span>
            <date-picker-custom
              :label="'Date maximum'"
              :date="maxDate"
              @update:date="maxDateChange"
            />
            <v-spacer />
            <v-btn small depressed color="primary" @click.stop="getGlobalPayStat" :loading="loadingGlobalStatPay">
              {{ $t("Filtrer") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th></th>
              <th class="text-left">{{ $t("Dépôt") }}</th>
              <th class="text-left">{{ $t('Retrait') }}</th>
              <th class="text-left">{{ $t('Frais de trans.') }}</th>
              <th class="text-left">{{ $t('Frais de Trans. Sup') }}</th>
            </tr>

            </thead>

            <tbody>
            <tr>
              <td>{{ $t("Qté") }}</td>
              <td  >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>
              <td >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>
              <td >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>
              <td >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>

            </tr>
            <tr>
              <td>{{ $t("Coût") }}</td>
              <td  >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>
              <td >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>
              <td >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>
              <td >
                <div class="d-flex justify-space-between">
                  <div>22</div>
                  <div>
                    <span>3%</span>
                    <v-icon
                      size="14"
                      :color="'error'"
                    >
                      {{ icons.mdiArrowDown }}
                    </v-icon>
                  </div>
                </div>
              </td>

            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="2">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">{{ $t("Solde Compte") }}</th>
            </tr>

            </thead>

            <tbody>
            <tr>
              <td class="text-center">
                <div>{{ formatAmount($store.state.payment.globalStatValue[0].value) }}</div>
              </td>

            </tr>
            <tr>
              <td class="text-center">
                <div>
                  <span>0%</span>
                  <v-icon
                    size="14"
                    :color="'error'"
                  >
                    {{ icons.mdiArrowDown }}
                  </v-icon>
                </div>
              </td>

            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="2">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">{{ $t("Frais de service") }}</th>
            </tr>

            </thead>

            <tbody>
            <tr>
              <td class="text-center">
                <div>{{ formatAmount($store.state.payment.globalStatValue[0].fee) }}</div>
              </td>

            </tr>
            <tr>
              <td class="text-center">
                <div>
                  <span>0%</span>
                  <v-icon
                    size="14"
                    :color="'error'"
                  >
                    {{ icons.mdiArrowDown }}
                  </v-icon>
                </div>
              </td>

            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="8">
        <v-card>
          <vue-apex-charts
            type="line"
            height="400"
            :options="lineAreaChartSpline.chartOptions"
            :series="lineAreaChartSpline.series"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="4">

      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiLabelVariantOutline, mdiCashMinus, mdiCashPlus, mdiArrowDown, mdiArrowUp } from '@mdi/js'
import UserStats from '@core/components/statistics/UserStats'
import BarChart from '@core/components/statistics/BarChart'
import { onMounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import DoughnutChart from '@core/components/statistics/DoughnutChart'
import AmountStat from '@core/components/statistics/AmountStat'
import store from '@/store'
import { intToCurrency } from '@core/dynamicComponents/dynamicComponents'
import VueApexCharts from 'vue-apexcharts'
import themeConfig from '@themeConfig'

export default {
  components: {
    AmountStat,
    DoughnutChart,
    DatePickerCustom,
    BarChart,
    UserStats,
    VueApexCharts,
  },
  setup() {
    const loading = ref(false)
    const loadingWithdraw = ref(false)
    const loadingWithdrawValue = ref(false)
    const loadingDeposit = ref(false)
    const loadingReason = ref(false)
    const loadingValidity = ref(false)
    const loadingGlobalStatPay = ref(false)
    const minDate = ref(null)
    const maxDate = ref(null)
    const data = ref([])
    const cat = ref([])

    const componentKey = ref(0)
    const componentWithdrawKey = ref(0)
    const componentWithdrawValueKey = ref(0)
    const componentDepositValueKey = ref(0)
    const componentReasonKey = ref(0)
    const componentValidityKey = ref(0)
    const componentPayStatKey = ref(0)
    const lineAreaChartSpline = ref({
      series: [
        {
          name: 'Visits',
          data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
        },
        {
          name: 'Clicks',
          data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
        },
        {
          name: 'Sales',
          data: [20, 40, 30, 70, 40, 60, 50, 140, 120, 100, 140, 180, 220],
        },
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          xaxis: {
            title: "Period"
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: [themeConfig.themes.light.secondary],
          colors: [
            '#1f9007',
            '#ef0013',
            '#5a00ff',],
        },
        colors: [
          '#1f9007',
          '#ef0013',
          '#5a00ff',],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 1,
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
            title: "Laybe",
            label: { show: true,  text: "Series A", title: "Sehj" },
          },
        },
        tooltip: {
          shared: true,
        },
        /*tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }%</span></div>`
          },
        },*/
       /* chart: {
          toolbar: {
            show: false,
          },
        },*/
        /*dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
        },*/
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },

        xaxis: {
          categories: [
            '7/12',
            '8/12',
            '9/12',
            '10/12',
            '11/12',
            '12/12',
            '13/12',
            '14/12',
            '15/12',
            '16/12',
            '17/12',
            '18/12',
            '19/12',
            '20/12',
          ],
        },
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 1,
          type: 'solid',
        },


      },
    })

    const newProjectOptions = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const forceRerender = () => {
      componentKey.value += 1
    }

    const forceRerenderWithdrawChart = () => {
      componentWithdrawKey.value += 1
    }

    const forceRerenderWithdrawValueChart = () => {
      componentWithdrawValueKey.value += 1
    }

    const forceRerenderDepositValueChart = () => {
      componentDepositValueKey.value += 1
    }

    const forceRerenderReasonValueChart = () => {
      componentReasonKey.value += 1
    }

    const forceRerenderValidityValueChart = () => {
      componentValidityKey.value += 1
    }

    const forceRerenderGlobalPayStat = () => {
      componentPayStatKey.value += 1
    }

    const filterPay = event => {
      loading.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterStat.min_date = event.date
          } else {
            store.state.payment.filterStat.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getPayChartData', store.state.payment.filterStat).then(() => {
        loading.value = false
        forceRerender()
      }).catch(error => {
        console.log(error)
        loading.value = false
      })
    }

    const filterBooking = event => {
      loadingDeposit.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterDepositValueStat.min_date = event.date
          } else {
            store.state.payment.filterDepositValueStat.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getDepositValueChartData', store.state.payment.filterDepositValueStat).then(() => {
        loadingDeposit.value = false
        forceRerenderDepositValueChart()
      }).catch(error => {
        console.log(error)
        loadingDeposit.value = false
      })
    }

    const filterWithdrawValue = event => {
      loadingWithdrawValue.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterWithdrawValueStat.min_date = event.date
          } else {
            store.state.payment.filterWithdrawValueStat.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getWithdrawValueChartData', store.state.payment.filterWithdrawValueStat).then(() => {
        loadingWithdrawValue.value = false
        forceRerenderWithdrawValueChart()
      }).catch(error => {
        console.log(error)
        loadingWithdrawValue.value = false
      })
    }

    const filterWithdraw = event => {
      loadingWithdraw.value = true
      data.value = []
      cat.value = []
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterWithdraw.min_date = event.date
          } else {
            store.state.payment.filterWithdraw.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getWithdrawChartData', store.state.payment.filterWithdraw).then(() => {
        loadingWithdraw.value = false
        forceRerenderWithdrawChart()
      }).catch(error => {
        console.log(error)
        loadingWithdraw.value = false
      })
    }

    const filterReason = event => {
      loadingReason.value = true
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterReason.min_date = event.date
          } else {
            store.state.payment.filterReason.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getReasonValueChartData', store.state.payment.filterReason).then(() => {
        loadingReason.value = false
        forceRerenderReasonValueChart()
      }).catch(error => {
        console.log(error)
        loadingReason.value = false
      })
    }

    const filterValidity = event => {
      loadingValidity.value = true
      if (event !== undefined) {
        if (event.lang === false) {
          if (event.isMin === true) {
            store.state.payment.filterValidity.min_date = event.date
          } else {
            store.state.payment.filterValidity.max_date = event.date
          }
        }
      }
      store.dispatch('payment/getValidityValueChartData', store.state.payment.filterValidity).then(() => {
        loadingValidity.value = false
        forceRerenderValidityValueChart()
      }).catch(error => {
        console.log(error)
        loadingValidity.value = false
      })
    }

    const getGlobalPayStat = () => {
      loadingGlobalStatPay.value = true
      store.dispatch('payment/getGlobalStatData').then(() => {
        loadingGlobalStatPay.value = false
        forceRerenderGlobalPayStat()
      }).catch(error => {
        loadingGlobalStatPay.value = false
        console.log(error)
      })
    }

    const minDateChange = event => {
      minDate.value = event
      //fitlerStat(event, true)
    }

    const maxDateChange = event => {
      maxDate.value = event
      //fitlerStat(event, false)
    }
    const fitlerStat = (date, isMin) => {
      const lang = false
      getGlobalPayStat()
      //instance.emit('mount', { date, isMin, lang })

     /* doughnutChart.data.datasets[0].labels = []
      doughnutChart.data.datasets[0].labels.push(...props.cat)
      doughnutChart.data.datasets[0].data = []
      doughnutChart.data.datasets[0].data.push(...props.dat)
      doughnutChart.data.datasets[0].backgroundColor = []
      doughnutChart.data.datasets[0].backgroundColor.push(...props.color)
      initStockData()*/
    }

    const initDashBoard = () => {
      setTimeout(() => {
        getGlobalPayStat()
        filterValidity()
        filterPay()
        filterBooking()
        filterWithdraw()
        filterWithdrawValue()
        filterReason()
      }, 1000)
    }
    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)
    watch(() => store.state.payment.filterValidity.refresh, () => {
      initDashBoard()
    }, { deep: true })

    onMounted(() => {
      initDashBoard()
    })

    return {
      lineAreaChartSpline,
      formatAmount,
      minDate,
      maxDate,
      getGlobalPayStat,
      maxDateChange,
      minDateChange,
      loadingGlobalStatPay,
      componentPayStatKey,
      newProjectOptions,
      componentKey,
      filterValidity,
      loadingDeposit,
      filterWithdrawValue,
      filterBooking,
      filterReason,
      loadingWithdrawValue,
      componentDepositValueKey,
      data,
      cat,
      loading,
      loadingWithdraw,
      filterPay,
      filterWithdraw,
      componentWithdrawKey,
      componentWithdrawValueKey,
      componentReasonKey,
      loadingReason,
      loadingValidity,
      componentValidityKey,
      icons : {
        mdiCashPlus,
        mdiCashMinus,
        mdiArrowDown,
        mdiArrowUp,
      }
    }
  },

}
</script>
